import { Injector } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppStarterService } from "@digic/appstarter";
import { environment } from "src/environments/environment";

import { AppModule } from "./app/app.module";
import { CONFIG_DATA } from "./configData";

const injector = Injector.create({
  providers: [{ provide: AppStarterService }],
});

const appStarterService = injector.get(AppStarterService);

appStarterService
  .load(environment.configServiceUrl)
  .then((config) => {
    platformBrowserDynamic([{ provide: CONFIG_DATA, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  })
  .catch((error) => {
    console.error("Failed to load configuration", error);
    throw error;
  });
