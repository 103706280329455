import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { MenuModule } from "@progress/kendo-angular-menu";
import { TooltipModule } from "@progress/kendo-angular-tooltip";
import { AppRoutingModule } from "./app-routing.module";

import { DialogModule } from "@digic/dialog";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { NotificationModule } from "@progress/kendo-angular-notification";

import { ENVIRONMENTER as AnnotatedImageEnvironmenter, initializeElement } from "@digic/annotated-image";
import { ENVIRONMENTER as LayoutEnvironmenter, LayoutComponent, LayoutModule as dAppLayoutModule } from "@digic/layout";
import { ENVIRONMENTER as NotificationsEnvironmenter } from "@digic/notifications";
import {
  AuthenticationService,
  DefaultEmployeeService,
  DefaultLocalizationService,
  DefaultNotificationService,
  EmployeeServiceBase,
  ENVIRONMENTER,
  HealthcheckService,
  HttpErrorInterceptor,
  HttpLoaderFactory,
  initializeKeycloak,
  LocalizationServiceBase,
  NotificationServiceBase,
  SharedModule,
  WinAuthInterceptor,
} from "@digic/shared";
import { VersionEnvironmenter } from "@digic/versions";

import { ENVIRONMENTER as ImageEnvironmenter, ImageService, initializeElement as initializeImageElement } from "@digic/image";

import { CommentFlowModule, ENVIRONMENTER as CommentFlowEnvironmenter } from "@digic/comment-flow";

import { CdkTreeModule } from "@angular/cdk/tree";
import { HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { CONFIG_DATA } from "src/configData";
import { environment } from "src/environments/environment";
import { VersionFilterComponent } from "./dnovus/components/version-view/version-filter/version-filter.component";
import { VersionsComponent } from "./dnovus/components/version-view/versions/versions.component";
import { DnovusModule } from "./dnovus/dnovus.module";

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    CdkTreeModule,
    BrowserAnimationsModule,

    //Kendo modules
    MenuModule,
    ButtonsModule,
    NotificationModule,
    InputsModule,
    TooltipModule,
    DialogsModule,
    DropDownsModule,

    DialogModule,
    LayoutModule,
    SharedModule,
    CommentFlowModule,

    dAppLayoutModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "hu",
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
  ],
  providers: [
    ImageService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: NotificationServiceBase, useClass: DefaultNotificationService },
    { provide: LocalizationServiceBase, useClass: DefaultLocalizationService },
    { provide: EmployeeServiceBase, useClass: DefaultEmployeeService },
    { provide: ENVIRONMENTER, useFactory: (config) => config, deps: [CONFIG_DATA] },
    { provide: LayoutEnvironmenter, useFactory: (config) => config, deps: [CONFIG_DATA] },
    { provide: NotificationsEnvironmenter, useFactory: (config) => config, deps: [CONFIG_DATA] },
    { provide: AnnotatedImageEnvironmenter, useFactory: (config) => config, deps: [CONFIG_DATA] },
    { provide: CommentFlowEnvironmenter, useFactory: (config) => config, deps: [CONFIG_DATA] },
    { provide: ImageEnvironmenter, useFactory: (config) => config, deps: [CONFIG_DATA] },
    { provide: VersionEnvironmenter, useFactory: (config) => config, deps: [CONFIG_DATA] },

    // ResourceGroupService,

    {
      provide: APP_INITIALIZER,
      useFactory: initializeImageElement,
      multi: true,
      deps: [Injector],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeElement,
      multi: true,
      deps: [Injector],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, AuthenticationService, HealthcheckService],
    },
  ],
  bootstrap: [LayoutComponent],
})
export class AppModule {}
