import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "@digic/shared";

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: "reload",
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 64]
};

const routes: Routes = [
  { path: "", redirectTo: "dapp", pathMatch: "full" },
  {
    path: "dapp",
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    loadChildren: () =>
      import("./dnovus/dnovus.module").then(
        (mod) => mod.DnovusModule
      ),
    data: { title: "dApp" },
  },
  { path: "**", redirectTo: "dapp", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
